<template>
  <div class="border10">
    <dv-border-box-10>
      <div v-if="name" class="section">
        <div v-if="name !== '监控视屏'" class="name flex">
          <div class="flex left" v-if="name !== 'Detection'">
            <img class="nameImage" src="../../assets/aboutName.png" alt="" />
            <div>
              {{ name }}
              <span v-if="name === '市场当天交易走势'" class="nameType"
                >(交易量/交易额)</span
              >
            </div>
          </div>
          <div class="right flex" v-if="name === '食品安全快检信息'">
            <div
              :class="['blue', type === 'month' ? 'blues' : '']"
              @click="detection('month')"
            >
              月度
            </div>
            <div
              :class="['blue', type === 'quarter' ? 'blues' : '']"
              @click="detection('quarter')"
            >
              季度
            </div>
            <div
              :class="['blue', type === 'year' ? 'blues' : '']"
              @click="detection('year')"
            >
              年度
            </div>
          </div>
          <div class="right flex" v-if="name === '市场当天交易走势'">
            <div class="count">交易量</div>
            <div class="price">交易额</div>
          </div>
        </div>
        <div
          class="slot"
          :style="{
            height:
              name === '监控视屏' || name === 'Detection'
                ? '100%'
                : 'calc(100% - 0.3rem)'
          }"
        >
          <slot></slot>
        </div>
      </div>
      <div class="chunkBorder flex" v-else>
        <div class="flex" v-if="obj">
          <div class="objName">{{ obj.name }}</div>
          <div>{{ obj.num }}</div>
          <div class="objCount" v-if="obj.count">{{ obj.count }}</div>
        </div>
        <img class="objImage" v-if="obj" :src="obj.img" alt="" />
      </div>
    </dv-border-box-10>
  </div>
</template>

<script>
export default {
  name: 'border10',
  props: ['name', 'obj'],
  data () {
    return {
      type: ''
    }
  },
  created () {},
  methods: {
    detection (type = 'month') {
      if (this.type === type) return
      this.type = type
      // console.log(this.name, this.$children);
      this.$children[0].$children[0].getDetectionPassCount(type)
    }
  },
  mounted () {
    if (this.name === '食品安全快检信息') {
      this.detection()
    }
  }
}
</script>
<style lang="less" scoped>
.border10,
.chunkBorder,
.section {
  width: 100%;
  height: 100%;
  .section,
  .chunkBorder {
    padding: 0.1rem;
    box-sizing: border-box;
  }
  .slot {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .chunkBorder {
    padding: 0.3rem;
    font-size: 0.26rem;
    color: #fff;
    align-items: end;
    .objName {
      font-size: 0.24rem;
      color: #07dbff;
    }
    .objCount {
      font-size: 0.16rem;
    }
    .objImage {
      width: 0.7rem;
      height: 0.7rem;
    }
    .flex {
      flex-direction: column;
      height: 100%;
      align-items: flex-start;
    }
  }
  .name {
    font-size: 0.22rem;
    color: #07dbff;
    .nameImage {
      width: 0.5rem;
      height: 0.22rem;
      margin-right: 0.1rem;
    }
    .nameType {
      font-size: 0.16rem;
    }
    .left {
      justify-content: normal;
    }
    .right {
      font-size: 00.18rem;
      color: #fff;
      padding-right: 0.1rem;
      box-sizing: border-box;
      .blue {
        padding: 0.02rem 0.1rem;
        box-sizing: border-box;
        border: 1px solid #fff;
        border-radius: 0.04rem;
      }
      .blues {
        color: #07dbff;
        border: 1px solid #07dbff;
        box-shadow: #10adff 0px 0px 0.05rem 0.02rem inset;
      }
      .blue:nth-child(2) {
        margin: 0 0.2rem;
      }
      .count {
        margin-right: 00.5rem;
        position: relative;
      }
      .price {
        position: relative;
      }
      .price::before,
      .count::before {
        content: '';
        display: block;
        width: 0.2rem;
        height: 0.2rem;
        background: #fe9323;
        position: absolute;
        left: -0.3rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .price::before {
        background: #32a9fe;
      }
      //   border: 1px solid #fff;
    }
  }
}
</style>
