<template>
  <div class="Carousel" :style="about">
    <carousel
      :perPage="type === 'about' || type === 'marketName' ? 2.3 : 2.8"
      :paginationEnabled="false"
    >
      <template v-if="type === 'about'">
        <slide v-for="item in personnel" :key="item.id">
          <div class="slide">
            <dv-border-box-8>
              <div class="contetn flex">
                <img :src="item.icon" class="image" alt="" />
                <div class="list flex">
                  <div>{{ item.station.station_title }}</div>
                  <div>{{ item.name }}</div>
                  <div>{{ item.tel }}</div>
                </div>
              </div>
            </dv-border-box-8>
          </div>
        </slide>
      </template>
      <template v-else-if="type === 'marketName' && arr">
        <slide v-for="item in arr" :key="item.camera_code">
          <div class="slide">
            <dv-border-box-8>
              <div class="contetn flex">
                <img :src="item.img" class="image" alt="" />
                <div class="list flex">
                  <div>{{ item.job }}</div>
                  <div>{{ item.name }}</div>
                  <div>{{ item.tel }}</div>
                </div>
              </div>
            </dv-border-box-8>
          </div>
        </slide>
      </template>
      <template v-else>
        <slide v-for="(item, i) in videoList" :key="i">
          <div class="video" :ref="'video' + i">
            <div @click="playStop(item.status, item.url, i, item.position)" class="videoImage">
              <img
                style="width:100%;height:100%"
                :src="require('../../assets/' + item.status + '.png')"
                alt=""
              />
            </div>
            <span class="title">{{ item.position }}</span>
          </div>
        </slide>
      </template>
    </carousel>
  </div>
</template>

<script>
import { getPersonnel, getCamera } from '@api/index'

export default {
  name: 'Carousels',
  props: ['type', 'palyHls', 'arr'],
  data () {
    return {
      personnel: [],
      about: {},
      videoList: [],
      videoUrl: ''
    }
  },
  created () {
    if (this.type === 'about') {
      this.about = {
        padding: '0 0.1rem',
        'box-sizing': 'border-box'
      }
      this.getPersonnel()
    }
    if (this.type === 'videohls') {
      this.getCamera()
    }
  },
  methods: {
    getPersonnel () {
      getPersonnel({ market_id: this.$route.query.id }).then(res => {
        this.personnel = res.data
      })
    },
    getCamera () {
      getCamera({ market_id: this.$route.query.id }).then(res => {
        let arr = []
        res.data.forEach(item => {
          arr.push({ status: 'stop', url: item.url, position: item.position, camera_code: item.camera_code })
        })
        this.videoList = arr
        if (res.data.length === 0) return ''
        this.videoUrl = res.data[0].url
        this.palyHls(res.data[0].url, res.data[0].position)
      })
    },
    playStop (status, url, i, position) {
      this.palyHls(url, position)
      if (status === 'stop') {
        for (let n = 0; n < this.videoList.length; n++) {
          if (this['video' + n] && this.videoList[n].status === 'play') {
            this['video' + n].dispose()
            this.videoList[n].status = 'stop'
          }
        }
        const video = document.createElement('video')
        video.id = 'video' + i
        video.style = 'width:100%;height:100%;background:#333;object-fit: fill;'
        this.$refs['video' + i][0].appendChild(video)
        if (document.getElementById(`video${i}`)) {
          // 播放器配置
          const options = {
            bigPlayButton: false,
            textTrackDisplay: false,
            posterImage: false,
            errorDisplay: false,
            controlBar: false,
            muted: true,
            autoplay: true,
            controls: false // 是否显示控件
          }
          this['video' + i] = this.$videojs(`#video${i}`, options, () => {
            this['video' + i].src({
              src: url,
              type: 'application/x-mpegURL' // 修改这个type值
            })
          })
          this.videoList[i].status = 'play'
        }
      }
      if (status === 'play') {
        this['video' + i].dispose()
      }
    }
  },
  beforeDestroy () {
    for (let n = 0; n < this.videoList.length; n++) {
      if (this['video' + n]) {
        this['video' + n].dispose()
        this.videoList[n].status = 'stop'
      }
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.Carousel {
  width: 100%;
  height: 100%;
  /deep/.VueCarousel,
  /deep/ .VueCarousel-wrapper,
  /deep/ .VueCarousel-inner {
    width: 100%;
    height: 100% !important;
    // align-items: center;
  }
  /deep/.VueCarousel-slide {
    display: flex;
    align-items: center;
  }
  .slide {
    width: 95%;
    height: 80%;
    color: #fff;
    font-size: 0.24rem;
    box-shadow: #10adff 0px 0px 0.1rem 0.05rem inset;
  }
  .video {
    width: 90%;
    height: 100%;
    background: #555758;
    position: relative;
    .videoImage {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      top: calc(50% - 0.25rem);
      left: calc(50% - 0.25rem);
    }
    .title {
      position: absolute;
      right: 4px;
      bottom: 4px;
      font-size: 14px;
      color: #fff;
      z-index: 99;
      background-color: rgba(0,0,0,0.6);
      padding: 3px;
    }
  }
  .contetn {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0.05rem;
    box-sizing: border-box;
    .image {
      width: 50%;
      height: 100%;
    }
    .list {
      width: 50%;
      flex-direction: column;
      height: 100%;
      font-size: 0.16rem;
      justify-content: space-evenly;
      align-items: flex-start;
      color: aqua;
    }
  }
}
</style>
